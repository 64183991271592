import React, { useEffect, useState } from 'react';
import { Button, Flex, HStack, Spacer, Stack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';
import { updateUser } from 'src/api/services/user';
import dayjs from 'dayjs';
import { CheckIfNicknameIsAvailableInUpdateUser } from 'src/api/services/player';
import { getAddressByCep } from 'src/api/services/location';
import { FormMaskedInput } from 'src/components/Form/Input/MaskedInput';
import usePHToast from 'src/hooks/useToast';
import { clearNonDigits } from 'src/utils/clearNonDigits';
import { CreateAccountFormValues } from '../../CreateAccountForm';
import { FormSelect } from 'src/components/Form/Select';
import CountriesInfo from 'src/assets/CountriesInfo.json';

interface ProfileInfoFormValues {
  userId?: string;
  name?: string;
  lastName?: string;
  nickName?: string;
  birthDate?: string;
  document?: string;
  email?: string;
  address?: string;
  number?: string;
  zipCode?: any;
  complement?: string;
  city?: string | undefined;
  state: string | undefined;
  cityID?: number;
  stateID?: number;
  ddi?: number;
  phone?: string;
}

export default function ProfileInfoForm({
  userId,
  name,
  lastName,
  nickName,
  birthDate,
  document,
  email,
  zipCode,
  address,
  number,
  complement,
  city,
  state,
  ddi,
  phone,
  stateID,
  cityID,
}: ProfileInfoFormValues) {
  const updateUserForm = useForm<ProfileInfoFormValues>({
    values: {
      name: name ?? '',
      lastName: lastName ?? '',
      nickName: nickName ?? '',
      ddi: ddi ?? 55,
      phone: phone ?? '',
      document: document ?? '',
      birthDate: dayjs(birthDate).format('DD/MM/YYYY').toString() ?? '',
      zipCode: zipCode.replace(/-/g, '') ?? '',
      state: state ?? '',
      stateID: stateID ?? 0,
      city: city ?? '',
      cityID: cityID ?? 0,
      address: address ?? '',
      number: number ?? '',
      email: email ?? '',
      complement: complement ?? '',
    },
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = updateUserForm;

  const ddiValue = watch('ddi');

  const toast = usePHToast();

  const [loading, setLoading] = useState(false);
  const [editDisabled, setEditDisabled] = useState(true);

  const getAddresIDs = async () => {
    try {
      const res = await getAddressByCep(zipCode);
      setValue('address', res.logradouro);
      setValue('cityID', Number(res.ibge));
      setValue('stateID', Number(res.ibge.slice(0, 2)));
    } catch (err) {
      toast({ status: 'error', title: 'erro ao buscar endereço' });
    }
  };

  const handleCEPInput = async (cep: number) => {
    try {
      const result = await getAddressByCep(cep);
      if (result) {
        setValue('state', result.uf);
        setValue('city', result.localidade);
        setValue('cityID', Number(result.ibge));
        setValue('stateID', Number(result.ibge.slice(0, 2)));
      }
    } catch (err) {
      setValue('address', '');
      setValue('state', '');
      setValue('city', '');
      setValue('cityID', 0);
      setValue('stateID', 0);
      toast({ status: 'error', title: 'CEP inválido' });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      delete data['state'];
      delete data['city'];
      if (userId) {
        const req = await updateUser(userId, data);
      }
      toast({
        status: 'success',
        title: 'os dados do usuário foram editados com sucesso',
      });
    } catch (err) {
      toast({ status: 'error', title: 'erro ao editar dados do usuário' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAddresIDs();
    setValue('name', name);
    setValue('lastName', lastName);
    setValue('nickName', nickName);
    setValue('birthDate', birthDate);
    setValue('phone', phone);
    setValue('document', document);
    setValue('address', address);
    setValue('email', email);
    setValue('zipCode', zipCode);
    setValue('number', number);
    setValue('complement', complement);
    setValue('city', city);
    setValue('state', state);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={'column'} spacing={4}>
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('name', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.name}
            label={'NOME'}
            inputProps={{ placeholder: 'NOME' }}
          />
          <FormInput
            rhfRegister={{
              ...register('lastName', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.lastName}
            label={'SOBRENOME'}
            inputProps={{ placeholder: 'SOBRENOME' }}
          />
          <FormInput
            rhfRegister={{
              ...register('nickName', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
                validate: async (value) => {
                  if (value) {
                    const isNicknameAvailable: boolean =
                      await CheckIfNicknameIsAvailableInUpdateUser(
                        value,
                        userId
                      );
                    return isNicknameAvailable === true || 'Nickname já em uso';
                  }
                },
              }),
            }}
            errorState={errors.nickName}
            label={'NICKNAME'}
            inputProps={{ placeholder: 'NICKNAME' }}
          />
        </Stack>
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('email', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'E-mail inválido',
                },
              }),
            }}
            errorState={errors.email}
            label={'EMAIL'}
            inputProps={{ placeholder: 'EMAIL' }}
          />
          <FormSelect
            rhfRegister={{
              ...register('ddi'),
            }}
            errorState={errors.ddi}
            label={'DDI'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setValue('ddi', Number(e.target.value));
            }}
            readonly={editDisabled || loading}
          >
            {CountriesInfo.map((ct) => (
              <option key={ct.codigo} value={Number(ct.fone)}>{`${
                ct.nome
              } (${Number(ct.fone)})`}</option>
            ))}
          </FormSelect>
          <FormMaskedInput
            control={control}
            id={'phone'}
            mask={Number(ddiValue) === 55 ? '(99) 99999-9999' : ''}
            rules={{
              required: 'Campo obrigatório',
            }}
            errorState={errors.phone}
            label={'CONTATO'}
            inputProps={{
              placeholder: Number(ddiValue) === 55 ? '(11) 92667-9368' : '',
            }}
            disabled={editDisabled || loading}
          />
          <FormMaskedInput
            control={control}
            id={'birthDate'}
            mask={'99/99/9999'}
            rules={{
              required: 'Campo obrigatório',
              pattern: {
                value:
                  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i,
                message: 'Data inválida',
              },
            }}
            errorState={errors.birthDate}
            label={'DATA DE NASCIMENTO'}
            width="max-content"
            inputProps={{ placeholder: 'XX/XX/XXXX' }}
            disabled={editDisabled || loading}
          />
        </Stack>
        <Stack direction={['column', 'row']}></Stack>
        <Stack direction={['column', 'row']}>
          <FormMaskedInput
            control={control}
            id={'zipCode'}
            mask={'99999-999'}
            rules={{
              required: 'Campo obrigatório',
              onBlur: (e: any) => handleCEPInput(e.target.value),
            }}
            label={'CEP'}
            inputProps={{ placeholder: '04730-000' }}
            disabled={editDisabled || loading}
          />
          <FormInput
            rhfRegister={{
              ...register('city', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.city}
            readonly
            label={'CIDADE'}
            inputProps={{ placeholder: 'SÃO PAULO' }}
          />
          <FormInput
            rhfRegister={{
              ...register('state', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.state}
            label={'ESTADO'}
            readonly
            inputProps={{ placeholder: 'SP' }}
          />
        </Stack>
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('address', {
                required: 'Campo obrigatório',
                disabled: editDisabled || loading,
              }),
            }}
            errorState={errors.address}
            readonly
            label={'ENDEREÇO'}
            inputProps={{ placeholder: 'DIGITE SEU ENDEREÇO' }}
          />
          <FormInput
            rhfRegister={{
              ...register('number', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.number}
            label={'NÚMERO'}
            inputProps={{ placeholder: '123' }}
          />
          <FormInput
            rhfRegister={{
              ...register('complement', {
                disabled: editDisabled || loading,
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.complement}
            label={'COMPLEMENTO'}
            inputProps={{ placeholder: 'APTO 12' }}
          />
        </Stack>
      </Stack>
      <Flex mt={6} w="100%">
        <Spacer />
        {editDisabled ? (
          <Button
            onClick={() => setEditDisabled(false)}
            variant="pophausOutline"
          >
            Editar Dados
          </Button>
        ) : (
          <HStack>
            <Button
              variant="pophausOutline"
              colorScheme="popTransparent"
              onClick={() => setEditDisabled(true)}
              isLoading={loading}
            >
              Cancelar
            </Button>
            <Button isLoading={loading} variant="pophausOutline" type="submit">
              Confirmar
            </Button>
          </HStack>
        )}
      </Flex>
    </Form>
  );
}
