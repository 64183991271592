import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './redux/store';
import TagManager from 'react-gtm-module';
import TiktokPixel from 'tiktok-pixel';
import { BrowserRouter } from 'react-router-dom';

// Google tag manager
const tagManagerArgs = {
  gtmId: 'GTM-58TVWPX', // trocar para uma env variable
};
TagManager.initialize(tagManagerArgs);
TiktokPixel.init('CK0S1SRC77U5E796D6SG');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
