import { Spinner, Stack } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn, useForm, useWatch } from 'react-hook-form';
import { cpf } from 'cpf-cnpj-validator';
import { CEPResult } from 'src/api/interfaces/user';
import {
  checkIfNicknameIsAvailableForNewUser,
  CheckIfNicknameIsAvailableInUpdateUser,
} from 'src/api/services/player';
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';
import { FormMaskedInput } from 'src/components/Form/Input/MaskedInput';
import { FormInputPassword } from 'src/components/Form/Input/PasswordInput';
import { getAddressByCep } from 'src/api/services/location';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import usePHToast from 'src/hooks/useToast';
import { useDebounce } from 'src/hooks/useDebounce';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { FormSelect } from 'src/components/Form/Select';
import CountriesInfo from 'src/assets/CountriesInfo.json';

export interface CreateAccountFormValues {
  name: string;
  lastName: string;
  nickName: string;
  birthDate: string;
  ddi: number;
  phone: string;
  brazilianDocument: boolean;
  document: string;
  zipCode: string;
  address: string;
  number: string;
  complement: string;
  city: string;
  cityID: number;
  state: string;
  stateID: number;
  email: string;
  password: string;
  confirmPassword: string;
}

interface CreateAccountFormProps {
  rhfForm: UseFormReturn<CreateAccountFormValues, any>;
  isUpdate?: boolean;
}

type NicknameCheckStatusType = 'LOADING' | 'IDLE' | 'TAKEN' | 'AVAILABLE';

export default function CreateAccountForm({
  rhfForm,
  isUpdate = false,
}: CreateAccountFormProps) {
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
    watch,
  } = rhfForm;
  const nicknameValue = watch('nickName');

  const [nickNameCheckStatus, setNickNameCheckStatus] =
    useState<NicknameCheckStatusType>('IDLE');
  const debouncedValue = useDebounce<string>(nicknameValue, 300);
  const toast = usePHToast();
  const [brazilianDocument, setBrazilianDocument] = useState<boolean>(
    watch('brazilianDocument')
  );
  const [ddiValue, setDDIValue] = useState<number>(Number(watch('ddi')));

  const { user } = useSelector((state: RootState) => state.user);

  const handleCEPInput = async (cep: number) => {
    try {
      const result = await getAddressByCep(cep);
      if (result) {
        setValue('address', result.logradouro);
        setValue('state', result.uf);
        setValue('city', result.localidade);
        setValue('cityID', Number(result.ibge));
        setValue('stateID', Number(result.ibge.slice(0, 2)));
      }
    } catch (err) {
      toast({ status: 'error', title: 'erro ao buscar endereço' });
    }
  };

  useEffect(() => {
    if (!nicknameValue) {
      setNickNameCheckStatus('IDLE');
      return;
    }

    const checkNicknameAvailability = async () => {
      setNickNameCheckStatus('LOADING');
      let checkNicknameAvailabilityResponse;

      if (isUpdate) {
        checkNicknameAvailabilityResponse =
          await CheckIfNicknameIsAvailableInUpdateUser(
            nicknameValue,
            user?.userID
          );
      } else {
        checkNicknameAvailabilityResponse =
          await checkIfNicknameIsAvailableForNewUser(nicknameValue);
      }

      if (checkNicknameAvailabilityResponse) {
        setNickNameCheckStatus('AVAILABLE');
      } else {
        setNickNameCheckStatus('TAKEN');
      }
    };

    checkNicknameAvailability();
  }, [debouncedValue]);

  console.log({ errors });

  return (
    <Form>
      <Stack direction={'column'} spacing={4}>
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('name', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.name}
            label={'NOME'}
            inputProps={{ placeholder: 'NOME' }}
          />
          <FormInput
            rhfRegister={{
              ...register('lastName', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.lastName}
            label={'SOBRENOME'}
            inputProps={{ placeholder: 'SOBRENOME' }}
          />
        </Stack>
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('nickName', {
                required: 'Campo obrigatório',
                validate: () => {
                  return (
                    nickNameCheckStatus !== 'TAKEN' || 'Nickname já em uso'
                  );
                },
              }),
            }}
            rightIcon={
              nickNameCheckStatus === 'IDLE'
                ? null
                : nickNameCheckStatus === 'LOADING'
                ? Spinner
                : nickNameCheckStatus === 'TAKEN'
                ? CloseIcon
                : CheckIcon
            }
            rightIconColor={
              nickNameCheckStatus === 'LOADING'
                ? 'gray'
                : nickNameCheckStatus === 'TAKEN'
                ? 'red'
                : 'green'
            }
            errorState={errors.nickName}
            label={'NICKNAME'}
            inputProps={{ placeholder: 'NICKNAME' }}
          />
          <FormSelect
            rhfRegister={{
              ...register('ddi'),
            }}
            errorState={errors.ddi}
            label={'DDI'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setDDIValue(Number(e.target.value));
            }}
          >
            {CountriesInfo.map((ct) => (
              <option key={ct.codigo} value={Number(ct.fone)}>{`${
                ct.nome
              } (${Number(ct.fone)})`}</option>
            ))}
          </FormSelect>
          <FormMaskedInput
            control={control}
            id={'phone'}
            mask={Number(ddiValue) === 55 ? '(99) 99999-9999' : ''}
            rules={{
              required: 'Campo obrigatório',
            }}
            errorState={errors.phone}
            label={'CONTATO'}
            inputProps={{
              placeholder: Number(ddiValue) === 55 ? '(11) 92667-9368' : '',
            }}
          />
        </Stack>
        <Stack direction={['column', 'row']}>
          <FormSelect
            rhfRegister={{
              ...register('brazilianDocument'),
            }}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setBrazilianDocument(e.target.value === 'true');
            }}
            errorState={errors.brazilianDocument}
            label={'Documento brasileiro?'}
          >
            <option value={'true'}>Sim</option>
            <option value={'false'}>Não</option>
          </FormSelect>
          <FormMaskedInput
            control={control}
            id={'document'}
            mask={
              [true, 'true'].includes(brazilianDocument) ? '999.999.999-99' : ''
            }
            rules={{
              required: 'Campo obrigatório',
              // validate: (value: string) => {
              //   return cpf.isValid(value) || 'CPF inválido';
              // },
            }}
            errorState={errors.document}
            label={
              [true, 'true'].includes(brazilianDocument) ? 'CPF' : 'Documento'
            }
            inputProps={{
              placeholder: [true, 'true'].includes(brazilianDocument)
                ? '123.456.789-00'
                : undefined,
            }}
          />
        </Stack>
        <FormMaskedInput
          control={control}
          id={'birthDate'}
          mask={'99/99/9999'}
          rules={{
            required: 'Campo obrigatório',
            pattern: {
              value:
                /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i,
              message: 'Data inválida',
            },
          }}
          errorState={errors.birthDate}
          label={'DATA DE NASCIMENTO'}
          inputProps={{ placeholder: 'XX/XX/XXXX' }}
        />
        <FormMaskedInput
          control={control}
          id={'zipCode'}
          mask={'99999-999'}
          rules={{
            required: 'Campo obrigatório',
            // pattern: {
            //   value: /^[0-9]{5}-[0-9]{3}$/g,
            //   message: 'CEP Inválido',
            // },
            onBlur: (e: any) => handleCEPInput(e.target.value),
          }}
          errorState={errors.zipCode}
          label={'CEP'}
          inputProps={{ placeholder: '04730-000' }}
        />

        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('state', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.state}
            label={'ESTADO'}
            readonly
            inputProps={{ placeholder: 'SP' }}
          />
          <FormInput
            rhfRegister={{
              ...register('city', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.city}
            label={'CIDADE'}
            readonly
            inputProps={{ placeholder: 'SÃO PAULO' }}
          />
        </Stack>
        <FormInput
          rhfRegister={{
            ...register('address', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.address}
          label={'ENDEREÇO'}
          readonly
          inputProps={{ placeholder: 'DIGITE SEU ENDEREÇO' }}
        />
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('number', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.number}
            label={'NÚMERO'}
            inputProps={{ placeholder: '123' }}
          />
          <FormInput
            rhfRegister={{
              ...register('complement'),
            }}
            errorState={errors.complement}
            label={'COMPLEMENTO'}
            inputProps={{ placeholder: 'APTO 12' }}
          />
        </Stack>
        <FormInput
          rhfRegister={{
            ...register('email', {
              required: 'Campo obrigatório',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'E-mail inválido',
              },
            }),
          }}
          errorState={errors.email}
          label={'E-MAIL'}
          inputProps={{ placeholder: 'DIGITE SEU E-MAIL' }}
        />
        {!isUpdate && (
          <>
            <Stack
              direction={['column', 'row']}
              display={isUpdate ? 'none' : 'flex'}
            >
              <FormInputPassword
                rhfRegister={{
                  ...register('password', {
                    required: isUpdate ? false : 'Campo obrigatório',
                    pattern: {
                      value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
                      message:
                        'Senha precisa conter ao menos: 1 letra maiúscula, 1 letra minúscula, 1 número e 1 símbolo. ',
                    },
                  }),
                }}
                errorState={errors.password}
              />
            </Stack>
            <Stack
              direction={['column', 'row']}
              display={isUpdate ? 'none' : 'flex'}
            >
              <FormInputPassword
                label={'CONFIRMAR SENHA'}
                rhfRegister={{
                  ...register('confirmPassword', {
                    required: isUpdate ? false : 'Campo obrigatório',
                    validate: (value) => {
                      if (isUpdate) return;
                      const { password } = getValues();
                      return (
                        password === value || 'As senhas devem ser iguais!'
                      );
                    },
                  }),
                }}
                errorState={errors.confirmPassword}
                formControlProps={{ pb: 4 }}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Form>
  );
}
