import React, { useEffect, useState, useRef } from 'react';
import TikTokPixel from 'tiktok-pixel';
import PageContainer from 'src/components/Container/PageContainer';
import {
  HStack,
  Text,
  VStack,
  Stack,
  Button,
  Center,
  Box,
  Input,
} from '@chakra-ui/react';

import DashedContainer from 'src/components/Container/DashedContainer';
import { RoomImage } from 'src/components/RoomImage';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BookingStatusEnum,
  updateBookingAmount,
  updateBookingStatus,
  useGetBookingByID,
} from 'src/api/services/booking';
import dayjs from 'dayjs';
import usePHToast from 'src/hooks/useToast';
import { Loading } from 'src/components/Loading';
import { removeCoupon, validateCoupon } from 'src/api/services/coupon';
import EventTypeInput from '../../../client/events/events-summary/EventTypeInput';
import EventCouponHandler from '../../../client/events/events-summary/EventCouponHandler';
import { formatCurrency } from 'src/utils/format';
import * as fbq from '../../../../utils/pixels/fpixel';
import { putUpdateEventType } from 'src/api/services/event';
import { CouponFormValues } from 'src/pages/client/events/events-summary';
import { useForm } from 'react-hook-form';

export default function AdminEventsSummary() {
  const navigate = useNavigate();
  const couponForm = useForm<CouponFormValues>();
  const { handleSubmit } = couponForm;
  const [eventType, setEventType] = useState<string>('');
  const [eventTypesLoading, setEventTypesLoading] = useState<boolean>(true);
  const toast = usePHToast();
  const [loading, setLoading] = useState(false);
  const { bookingID } = useParams();
  // get
  const { data, isLoading, mutate } = useGetBookingByID(bookingID);

  const [totalPrice, setTotalPrice] = useState(0);
  const [priceEditing, setPriceEditing] = useState(false);
  const [isUpdateAmountOnRequest, setIsUpdateAmountOnRequest] = useState(false);

  useEffect(() => {
    if (data) setTotalPrice(data.total);
  }, [data]);

  useEffect(() => {
    toast({
      status: 'info',
      title: 'Trabalhando no problema do input de edição de preços',
    });
  }, []);

  const handleCouponApply = async (coupon: CouponFormValues) => {
    try {
      setLoading(true);
      if (!data) throw new Error('Erro ao identificar reserva');

      if (data.coupon != null) await removeCoupon(data.bookingID);
      if (!coupon) throw new Error('Favor digitar cupom');
      const res = await validateCoupon(
        coupon.coupon,
        data.bookingID,
        data.userID
      );
      await mutate();

      if (!res) throw new Error('Erro ao aplicar cumpom');
      if (!res.available) throw new Error('Cupom indisponível');

      toast({ status: 'success', title: 'Cupom aplicado' });
    } catch (err: any) {
      toast({ status: 'error', title: err.message });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveCoupon = async () => {
    try {
      setLoading(true);
      if (!data)
        throw new Error(
          'Não foi possível identificar sua reserva, tente novamente.'
        );

      const res = await removeCoupon(data.bookingID);
      await mutate();
      if (!res) throw new Error('Erro ao remover cupom');
      toast({ status: 'success', title: 'Cupom removido' });
    } catch (err: any) {
      toast({ status: 'error', title: err.message });
    } finally {
      setLoading(false);
    }
  };

  const handlePriceEditing = async () => {
    try {
      if (!data) throw new Error('Erro ao identificar reserva');
      if (data.total === totalPrice) return setPriceEditing(false);
      setIsUpdateAmountOnRequest(true);
      await updateBookingAmount(data.bookingID, totalPrice);
      toast({
        status: 'success',
        title: 'Valor da reserva alterado com sucesso',
      });
    } catch (e: any) {
      toast({ status: 'error', title: e.message });
    } finally {
      setPriceEditing(false);
      setIsUpdateAmountOnRequest(false);
    }
  };

  const handleContinue = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (!bookingID)
        throw new Error(
          'Não foi possível identificar sua reserva, tente novamente.'
        );

      // Definir tipo de evento
      const eventSetTypeResult = await putUpdateEventType(bookingID, eventType);
      // erro =>
      if (!eventSetTypeResult)
        throw new Error('Problema ao definir tipo de evento');
      // sucesso => Alterar status para REALIZANDO_PAGAMENTO
      const result = await updateBookingStatus(
        bookingID,
        BookingStatusEnum.REALIZANDO_PAGAMENTO,
        {}
      );
      // erro =>
      if (!result) throw new Error('Problema ao atualizar o status da reserva');
      // sucesso => Continuar para pagamento
      fbq.event('initiateCheckout');
      window.gtag('event', 'begin_checkout');
      TikTokPixel.track('InitiateCheckout', {});
      navigate(`/admin/ingressos/pagamento/${result.bookingID}`);
    } catch (e: any) {
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer restricted>
      <VStack w="full" spacing={12}>
        <Text color="green" fontSize="xl">
          RESUMO DA RESERVA
        </Text>
        <DashedContainer justify="center" py={12} px={12}>
          <VStack w="full" spacing={12} align="flex-start">
            <Stack direction={['column', 'row']} w="full" h="full" spacing={2}>
              {loading ? (
                <Center w="full">
                  <Loading />
                </Center>
              ) : (
                <>
                  <RoomImage
                    flex={1 / 2}
                    title="O arcade inteiro pra você"
                    multipleRooms
                  />

                  <DashedContainer flex={1 / 2} alignItems="normal">
                    {data ? (
                      <>
                        {data.bookingRooms[0] && (
                          <SummaryText
                            title="horário"
                            value={`${dayjs(
                              data.bookingRooms[0].startTime
                            ).format('HH:mm')} ÀS ${dayjs(
                              data.bookingRooms[0].endTime
                            ).format('HH:mm')}`}
                          />
                        )}
                        <SummaryText
                          title="data"
                          value={`${dayjs(data.startDate).format(
                            'DD/MM/YYYY'
                          )}`}
                        />
                        <SummaryText
                          title="duração"
                          value={`${data.durationFormatted.slice(1, 2)} Horas`}
                        />
                        <SummaryText
                          title="participantes"
                          value={`${data.tickets}`}
                        />
                        {priceEditing ? (
                          <HStack>
                            <Text color="green">SUBTOTAL:</Text>
                            <Input
                              type="number"
                              px={2}
                              boxShadow={0}
                              variant="flushed"
                              color={'#131313'}
                              bg="white"
                              _placeholder={{ color: 'gray' }}
                              value={totalPrice}
                              onChange={(e) =>
                                setTotalPrice(Number(e.target.value))
                              }
                            />
                            <Button
                              isLoading={isUpdateAmountOnRequest}
                              onClick={handlePriceEditing}
                              paddingInline={5}
                              variant="pophausOutline"
                            >
                              OK
                            </Button>
                          </HStack>
                        ) : (
                          <HStack>
                            <SummaryText
                              title={'SUBTOTAL'}
                              value={`${formatCurrency(totalPrice)}`}
                            />
                            <Button onClick={() => setPriceEditing(true)}>
                              Editar
                            </Button>
                          </HStack>
                        )}

                        <Box my={3} border="1px solid green" />

                        <Stack direction="column">
                          <EventTypeInput
                            value={eventType}
                            setter={setEventType}
                            setLoading={setEventTypesLoading}
                          />
                          <EventCouponHandler
                            data={data}
                            handleCouponSubmit={handleSubmit(handleCouponApply)}
                            rhfForm={couponForm}
                            loading={loading}
                            handleRemoveCoupon={handleRemoveCoupon}
                          />
                        </Stack>
                      </>
                    ) : (
                      <Loading />
                    )}
                  </DashedContainer>
                </>
              )}
            </Stack>
            <Stack
              w="full"
              h={'full'}
              direction={['column', 'row']}
              spacing={2}
              justify="center"
            >
              <Button
                variant="pophausOutline"
                onClick={handleContinue}
                isLoading={loading || eventTypesLoading}
              >
                CONTINUAR
              </Button>
            </Stack>
          </VStack>
        </DashedContainer>
      </VStack>
    </PageContainer>
  );
}

const SummaryText = ({ title, value }: { title: string; value: string }) => {
  return (
    <HStack>
      <Text color="green" textTransform="uppercase">
        {title}:
      </Text>
      <Text color="white">{value}</Text>
    </HStack>
  );
};
