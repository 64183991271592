import React, { useState } from 'react';
import { Stack, StackProps, Text } from '@chakra-ui/react';
import { UseFormReturn } from 'react-hook-form';
import { FormInput } from 'src/components/Form/Input';
import { FormMaskedInput } from 'src/components/Form/Input/MaskedInput';
import { getPlayerByNickName } from 'src/api/services/player';
import ExistingPlayerConfirmationModal from './ExistingPlayerConfirmationModal';
import { PlayerResult } from 'src/api/interfaces/player';
import { FormSelect } from 'src/components/Form/Select';

export interface PlayerDataValues {
  nickName: string;
  name: string;
  lastName: string;
  brazilianDocument: boolean;
  document?: string;
  birthDate?: string;
  birthDateFormatted?: string;
}

export interface PlayerDataProps {
  playerNum: number;
  form: UseFormReturn<PlayerDataValues, any>;
  colorScheme?: 'green' | 'yellow';
  functionality?: 'add' | 'edit';
}

export default function PlayerDataForm({
  playerNum,
  form,
  colorScheme = 'green',
  functionality = 'add',
  ...props
}: PlayerDataProps & StackProps) {
  const [foundPlayer, setFoundPlayer] = useState(false);
  const {
    register,
    setValue,
    control,
    formState: { errors },
    watch,
  } = form;
  const [
    openExistingPlayerConfirmationModal,
    setOpenExistingPlayerConfirmationModal,
  ] = useState<boolean>(false);
  const [existingPlayer, setExistingPlayer] = useState<PlayerResult>();
  const [brazilianDocument, setBrazilianDocument] = useState<boolean>(
    watch('brazilianDocument')
  );

  const handleUserFound = (player: PlayerResult) => {
    setExistingPlayer(player);
    setOpenExistingPlayerConfirmationModal(true);
  };

  const handleConfirmExistingPlayer = (isConfirmed: boolean) => {
    if (!existingPlayer) return;

    if (isConfirmed) {
      setValue('name', existingPlayer.name);
      setValue('lastName', existingPlayer.lastName);
      setValue('brazilianDocument', existingPlayer.brazilianDocument);
      setValue('document', existingPlayer.document);
      setValue('birthDate', existingPlayer.birthDate);
      setValue('birthDateFormatted', existingPlayer.birthDateFormatted);
      setFoundPlayer(true);
    }

    setOpenExistingPlayerConfirmationModal(false);
    setExistingPlayer(undefined);
  };

  const handleNickname = async (e: any) => {
    try {
      const value: string = e.target.value;
      const player = await getPlayerByNickName(value);
      if (player.playerID === 0) return;
      if (player) handleUserFound(player);
    } catch (e) {
      setFoundPlayer(false);
      setValue('name', '');
      setValue('lastName', '');
      setValue('brazilianDocument', true);
      setValue('document', '');
      setValue('birthDate', '');
    }
  };

  console.log({ brazilianDocument });

  return (
    <Stack w="100%" direction={['column', 'row']} alignItems="end" {...props}>
      <Text
        color={colorScheme}
        fontSize={'4xl'}
        fontWeight="bold"
        lineHeight={'initial'}
      >
        {playerNum}
      </Text>
      <FormInput
        rhfRegister={{
          ...register('nickName', {
            required: 'Campo obrigatório',
            onBlur: handleNickname,
          }),
        }}
        errorState={errors.nickName}
        label={'NICKNAME*'}
        inputProps={{ placeholder: 'NICKNAME' }}
      />
      <FormInput
        rhfRegister={{
          ...register('name', {
            required: 'Campo obrigatório',
          }),
        }}
        errorState={errors.name}
        label={'NOME*'}
        inputProps={{ placeholder: 'NOME' }}
      />
      <FormInput
        rhfRegister={{
          ...register('lastName', { required: 'Campo obrigatório' }),
        }}
        errorState={errors.lastName}
        label={'SOBRENOME*'}
        inputProps={{ placeholder: 'SOBRENOME' }}
      />
      <FormSelect
        rhfRegister={{
          ...register('brazilianDocument'),
        }}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          setBrazilianDocument(e.target.value === 'true');
        }}
        errorState={errors.brazilianDocument}
        label={'Documento brasileiro?'}
      >
        <option value={'true'}>Sim</option>
        <option value={'false'}>Não</option>
      </FormSelect>
      <FormInput
        rhfRegister={{
          ...register('document', {
            minLength: [true, 'true'].includes(brazilianDocument)
              ? {
                  value: 11,
                  message: 'CPF inválido',
                }
              : undefined,
            disabled: foundPlayer,
          }),
        }}
        errorState={errors.document}
        label={
          [true, 'true'].includes(brazilianDocument) ? 'CPF*' : 'Documento*'
        }
        inputProps={
          [true, 'true'].includes(brazilianDocument)
            ? { placeholder: 'CPF', maxLength: 11 }
            : { placeholder: 'Documento' }
        }
      />

      {foundPlayer && (
        <FormInput
          rhfRegister={{ ...register('birthDateFormatted') }}
          errorState={errors.birthDateFormatted}
          label={'DATA DE NASCIMENTO'}
          inputProps={{ placeholder: 'DD/MM/AAAA', isDisabled: foundPlayer }}
        />
      )}

      {!foundPlayer && (
        <FormMaskedInput
          control={control}
          id={'birthDate'}
          mask="99/99/9999"
          rules={{
            required: 'Campo obrigatório',
            pattern: {
              value:
                /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i,
              message: 'Data inválida',
            },
          }}
          errorState={errors.birthDate}
          label={'DATA DE NASCIMENTO'}
          inputProps={{ placeholder: 'DD/MM/AAAA' }}
        />
      )}
      <ExistingPlayerConfirmationModal
        open={openExistingPlayerConfirmationModal}
        handleConfirm={handleConfirmExistingPlayer}
        existingPlayer={existingPlayer}
      />
    </Stack>
  );
}
