import { ApiRepository, ApiResponse, useFetch } from '../..';
import {
  BookingRequest,
  BookingResult,
  FilterCalendarInputModel,
  PaginatedListOfBookingsResult,
  PriceRequest,
  PriceResult,
  RescheduleAlertRequest,
  validateRecaptchaResult,
  validateRecaptchaRequest,
  BuyMoreTicketsRequest,
  BuyMoreTicketsResult,
  GetBookingTypesByConfigRequest,
  GetBookingTypesByConfigResult,
  GetSchedulesBySingleConfigRequest,
  GetSchedulesBySingleConfigResult,
} from 'src/api/interfaces/booking';
import { MatchResult } from 'src/api/interfaces/match';
import { EditPlayer, PlayerRequest } from 'src/api/interfaces/player';
import { ScheduleRuleRequest } from 'src/api/interfaces/scheduleRule';

const api = new ApiRepository();

export const BookingStatusEnum = {
  AGUARDANDO_USUARIO: 1,
  AGUARDANDO_PAGAMENTO: 2,
  REALIZANDO_PAGAMENTO: 3,
  PROCESSANDO_PAGAMENTO: 4,
  ERRO_PAGAMENTO: 5,
  CONFIRMADA: 6,
  UTILIZADA: 7,
  CANCELADA: 8,
  EXPIRADA: 9,
  RECIBO: [6, 7, 13, 15],
  IDENTIFICANDO_JOGADORES_PRE_PAGAMENTO: 17,
};

export async function createBooking(data: BookingRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'post',
    url: 'Booking',
    data,
  });
  return res.result;
}

export async function postReturnCalendar(data: FilterCalendarInputModel) {
  const res = await api.apiRequestWrapper<
    ApiResponse<FilterCalendarInputModel>
  >({
    method: 'post',
    url: 'Booking/FilterCalendar',
    data,
  });
  return res.result;
}

export async function postRescheduleCalendar(data: RescheduleAlertRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<RescheduleAlertRequest>>({
    method: 'post',
    url: 'Booking/RescheduleAlert',
    data,
  });
  return res.result;
}

export async function postAddScheduleRule(data: ScheduleRuleRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<ScheduleRuleRequest>>({
    method: 'post',
    url: 'Booking/AddScheduleRule',
    data,
  });
  return res.result;
}

export async function updateBooking(bookingID: string, data: BookingRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: 'Booking',
    params: { bookingID },
    data,
  });
  return res.result;
}

export async function updateBookingAmount(bookingID: string, amount: number) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: 'Booking/Amount',
    params: { bookingID },
    data: { amount },
  });
  return res.result;
}

export async function getBookingByID(bookingID: string) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'get',
    url: `Booking/${bookingID}`,
  });
  return res.result;
}

export function useGetBookingByID(bookingID?: string) {
  const args = bookingID
    ? {
        method: 'get',
        url: `Booking/${bookingID}`,
      }
    : null;

  const { data, error, isLoading, mutate, isValidating } = useFetch<
    ApiResponse<BookingResult>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate, isValidating };
}

export function useBookingFilter(
  startDate?: string,
  businessUnitID?: number,
  name?: string,
  bookingStatusID?: number
) {
  const args = {
    method: 'get',
    url: `Booking`,
    // params: { startDate, businessUnitID, name, bookingStatusID },
  };

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<BookingResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

export async function getBookingFilter(
  date?: string,
  businessUnitID?: string,
  name?: string
) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult[]>>({
    method: 'get',
    url: `Booking/Filter?Date=${date}&Name=${name}&BusinessUnitID=${businessUnitID}`,
  });
  return res.result;
}

export async function getBookingTicket(
  name?: string,
  startDate?: string,
  businessUnitID?: string
) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'get',
    url: `Booking?BusinessUnitID=${businessUnitID}&StartDate=${startDate}&Name=${name}`,
  });
  return res.result;
}

export async function cancelBooking(bookingID: string) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: `Booking/CancelBooking?BookingID=${bookingID}`,
  });
  return res.result;
}

export async function updateBookingStatus(
  bookingID: string,
  bookingStatusID: number,
  pixelData?: object
) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: `Booking/UpdateBookingStatus?BookingID=${bookingID}&BookingStatusID=${bookingStatusID}`,
    data: pixelData,
  });
  return res.result;
}

export async function addUserToBooking(bookingID: string, userID: string) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: `Booking/AddUserToBooking?BookingID=${bookingID}&UserID=${userID}`,
  });
  return res.result;
}

export async function addBookingPlayers(
  bookingID: string,
  playerRequest: PlayerRequest[] | EditPlayer[]
) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult>>({
    method: 'put',
    url: `Booking/AddBookingPlayers?BookingID=${bookingID}`,
    // params: {bookingID},
    data: playerRequest.map((p) => ({
      ...p,
      brazilianDocument: [true, 'true', undefined].includes(p.brazilianDocument)
        ? true
        : false,
    })),
  });
  return res.result;
}

export function useGetUserBookingHistory(UserID?: string) {
  const args = {
    method: 'get',
    url: `Booking/GetUserBookingsHistoric?UserID=${UserID}`,
  };

  const { data, error, isLoading } = useFetch<ApiResponse<BookingResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export function useGetUserActiveBooking(UserID?: string) {
  const args = {
    method: 'get',
    url: `Booking/GetUserActiveBookings?UserID=${UserID}`,
  };

  const { data, error, isLoading, mutate } = useFetch<
    ApiResponse<BookingResult[]>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading, mutate };
}

export async function removeBookingPlayers(
  BookingID: string,
  PlayerID: number
) {
  const res = await api.apiRequestWrapper<BookingResult>({
    method: 'delete',
    url: `Booking/RemoveBookingPlayers?BookingID=${BookingID}&PlayerID=${PlayerID}`,
  });
  return res;
}

export function useGetBookingsByPage(pageIndex: number) {
  const args = pageIndex
    ? {
        method: 'get',
        url: `user/getBookingsByPage?PageIndex=${pageIndex}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<
    ApiResponse<PaginatedListOfBookingsResult>
  >(api, args, { revalidateOnFocus: false });
  return { data: data?.result, error, isLoading };
}

export async function putIdentifyPlayers(
  BookingID: string,
  IdentifyPlayers?: boolean
) {
  const res = await api.apiRequestWrapper<BookingResult>({
    method: 'put',
    url: `Booking/IdentifyPlayers?BookingID=${BookingID}&IdentifyPlayers=${IdentifyPlayers}`,
  });
  return res;
}

export async function postReturnPrices(data: PriceRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<PriceResult>>({
    method: 'post',
    url: 'Booking/GetPrice',
    data,
  });
  return res.result;
}

export async function validateRecaptcha(data: validateRecaptchaRequest) {
  const res = await api.apiRequestWrapper<ApiResponse<validateRecaptchaResult>>(
    {
      method: 'post',
      url: 'Booking/ValidateCaptcha',
      data,
    }
  );
  return res.result;
}

export async function buyMoreTickets(bookingID: string, tickets: number) {
  const res = await api.apiRequestWrapper<ApiResponse<BuyMoreTicketsResult>>({
    method: 'post',
    url: `Booking/AddMoreTickets?BookingID=${bookingID}&Tickets=${tickets}`,
  });
  return res.result;
}

export function useGetMatchHistoryByBookingID(BookingID?: string) {
  const args = BookingID
    ? {
        method: 'get',
        url: `Booking/MatchRecords?BookingID=${BookingID}`,
      }
    : null;

  const { data, error, isLoading } = useFetch<ApiResponse<MatchResult[]>>(
    api,
    args,
    { revalidateOnFocus: false }
  );
  return { data: data?.result, error, isLoading };
}

export async function getBookingTypesByConfig(
  params: GetBookingTypesByConfigRequest
) {
  const res = await api.apiRequestWrapper<
    ApiResponse<GetBookingTypesByConfigResult[]>
  >({
    method: 'get',
    url: `Booking/GetBookingTypesByConfig`,
    params,
  });
  return res.result;
}

export async function getSchedulesBySingleConfig(
  params: GetSchedulesBySingleConfigRequest
) {
  const res = await api.apiRequestWrapper<
    ApiResponse<GetSchedulesBySingleConfigResult[]>
  >({
    method: 'get',
    url: `Booking/GetSchedulesBySingleConfig`,
    params,
  });
  return res.result;
}

export async function getBookingsByPlaceInRoomGroupID(
  placeInRoomGroupID: string
) {
  const res = await api.apiRequestWrapper<ApiResponse<BookingResult[]>>({
    method: 'get',
    url: `Booking/GetBookingsByPlaceInRoomGroupID?PlaceInRoomGroupID=${placeInRoomGroupID}`,
  });
  return res.result;
}
