import { createSlice } from '@reduxjs/toolkit';
import { CityResult } from 'src/api/interfaces/city';

export const ROLES = {
  ADMIN: 'systemadministrator',
};

interface User {
  userID: string;
  created: string;
  name: string;
  lastName: string;
  email: string;
  ddi: number;
  phone: string;
  brazilianDocument: boolean;
  document: string;
  address: string;
  number: string;
  complement?: string;
  cityID: number;
  stateID: number;
  city: CityResult;
  file?: string;
  birthDate: string;
  nickName: string;
  zipCode: string;
  picture?: string;
  roles: string[];
  accessToken: string;
}

interface UserState {
  user: User | null;
}

const initialUserState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
  },
});

export const UserActions = userSlice.actions;
export const userReducer = userSlice.reducer;
