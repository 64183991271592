import React, { useEffect, useState } from 'react';
import TikTokPixel from 'tiktok-pixel';
import { UseFormReturn } from 'react-hook-form';
import { FormSelect } from 'src/components/Form/Select';
import { Button, Stack, StackDirection } from '@chakra-ui/react';
import Form from 'src/components/Form/Form';
import { useGetBusinessUnits } from 'src/api/services/businessUnit';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoLocationOutline } from 'react-icons/io5';
import { FiCalendar, FiUsers } from 'react-icons/fi';
import { FormInput } from 'src/components/Form/Input';
import { BookingActions } from 'src/redux/booking';
import * as fbq from '../../../utils/pixels/fpixel';
import { useAppSelector } from 'src/redux/hooks';

export interface SelectTicketFormValues {
  date: string;
  businessUnitID: string;
  numberOfPlayers: string;
}

interface SelectTicketFormProps {
  form: UseFormReturn<SelectTicketFormValues, any>;
  overrideSubmit?: (data: SelectTicketFormValues) => Promise<void>;
  direction?: StackDirection;
  showSubmit?: boolean;
  readonly?: boolean;
  externalLoading?: boolean;
}

export default function SelectTicketForm({
  form,
  overrideSubmit,
  direction = ['column', 'row'],
  showSubmit = true,
  readonly = false,
  externalLoading = false,
}: SelectTicketFormProps) {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = form;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: businessUnits, isLoading } = useGetBusinessUnits();
  const { user } = useAppSelector((state) => state.user);

  const onSubmit = (data: SelectTicketFormValues) => {
    if (overrideSubmit) {
      overrideSubmit(data);
      return;
    }
    dispatch(BookingActions.setSearch(data));
    window.gtag('event', 'view_item');

    if (window.fbq) {
      window.fbq('track', 'AddToWishlist', {
        eventID: crypto.randomUUID(),
        event_source_url: window.location.href,
        action_source: 'website',
        event_name: 'AddToWishlist',
        client_user_agent: navigator.userAgent,
        event_time: Math.floor(Date.now() / 1000),
        first_name: user?.name,
        email: user?.email,
        phone: user?.phone,
      });
    }

    navigate('/ingressos');
  };

  const isMonday = (date: Date) => {
    const day = date.getDay();
    return day !== 1;
  };

  const unit = businessUnits?.find((item: any) => {
    return item.companyID === 1;
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} id="form-envio">
      <Stack direction={direction} w={'full'} spacing={6}>
        <FormInput
          rhfRegister={{
            ...register('date', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.date}
          icon={FiCalendar}
          inputProps={{ placeholder: 'DATA', type: 'date' }}
        />
        <FormSelect
          rhfRegister={{
            ...register('businessUnitID', {
              required: 'Campo obrigatório',
            }),
          }}
          errorState={errors.businessUnitID}
          icon={IoLocationOutline}
          selectUnit
        >
          <option value={1}>Unidade Santo Amaro</option>
        </FormSelect>

        <FormSelect
          rhfRegister={{
            ...register('numberOfPlayers', {
              required: 'Campo obrigatório',
              disabled: readonly,
            }),
          }}
          errorState={errors.numberOfPlayers}
          selectProps={{ placeholder: 'Número de Participantes', w: '100%' }}
          icon={FiUsers}
        >
          {Array.from({ length: 11 }).map((_, i) => (
            <option key={i} value={i + 2}>
              {i + 2}
            </option>
          ))}
        </FormSelect>

        {showSubmit && (
          <Button
            onClick={() => {
              TikTokPixel.track('AddToWishlist', {});
            }}
            isLoading={isLoading || externalLoading}
            type="submit"
            variant="pophausOutline"
            paddingInline={direction === 'column' ? 0 : 12}
          >
            continuar
          </Button>
        )}
      </Stack>
    </Form>
  );
}
